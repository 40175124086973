body {
  margin: 0;
  font-family: 'DroidSans', 'FuturaLT', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'DroidSans';
  src: url(./assets/fonts/DroidSans.woff) format('woff'),
      url('./assets/fonts/DroidSans.ttf')  format('truetype');
}

@font-face {
  font-family: 'FuturaLT';
  src: url(./assets/fonts/FuturaLT.woff) format('woff'),
      url('./assets/fonts/FuturaLT.ttf')  format('truetype');
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
